@import "~@lifeislife/lifeislife-resources/fonts/fontawesome5/font-awesome-5";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/primeng.min.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

// Custom style

html {
  font-size: 14px;
}

body {
  height: 100vh;
  position: relative;
  overflow: hidden;

  .app-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .image {
    }
  }
}
